import React, { useState, useEffect } from 'react'
import { isEmpty, useFirebase } from 'react-redux-firebase'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Button, Spinner, Modal, ModalBody, ButtonGroup } from 'reactstrap'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Snackbar from '@material-ui/core/Snackbar'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { connect } from 'react-redux'
import TopNavigation from '../TopNavigation'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'
import { useTranslation } from 'react-i18next'
import withAppconfig from '../AppContext'
import { Collapse, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useCookies } from 'react-cookie'
import Fab from '@material-ui/core/Fab'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MuiBadge from '@material-ui/core/Badge'
import { getWallet } from '../components/ViewUtils'
import { hasResource, getStatus } from '../components/ReservationUtil'
import ReservationRow from '../components/ReservationRow'
//import BottomNav from '../BottomNav'


const mapStateToProps = (state, props) => {
  const {
    firestore,
    firebase: { profile },
  } = state
  const { appconfig } = props
  if (firestore.data['ownReservations']) {
    let accesscode = profile.accesscode ? profile.accesscode[appconfig.accountkey] : null
    if (accesscode && appconfig.accesscodeextra) accesscode += appconfig.accesscodeextra

    let _m = Object.keys(firestore.data['ownReservations'])
      .map((key) => {
        return {
          ...firestore.data['ownReservations'][key],
          id: key,
          selectkey: key,
        }
      })
      .filter((r) => hasResource(r) && r.locationid === appconfig.accountkey && r.state !== 'user_cancelled')
      .map((r) => {
        if (r.isrecurring) {
          return r.recurrencyDates
            .map((rec) => {
              if (rec.state === 'moved') return null
              let _r = JSON.parse(JSON.stringify(r))
              let _sKey = _r.selectkey + '-'
              if (typeof rec === 'object') {
                _r.occurency = {
                  date: rec.date,
                  state: rec.state,
                  isstored: rec.isstored,
                  refundinvoicing: rec.refundinvoicing,
                  salestatus: rec.salestatus,
                }
                _sKey += rec.date
              } else {
                _r.occurency = { date: rec }
                _sKey += rec
              }
              _r.selectkey = _sKey // _r.selectkey + '-' + rec
              const timeOfDayHelper = moment.utc(_r.resourcegroups[0].end)
              const ordering = moment.utc(_r.occurency.date)
              ordering.hour(timeOfDayHelper.hour()).minute(timeOfDayHelper.minute())
              if (moment().utcOffset() > 0) {
                ordering.subtract(moment().utcOffset(), 'minute')
              } else {
                ordering.add(moment().utcOffset(), 'minute')
              }
              _r.ordering = ordering.unix()
              return _r
            })
            .filter((x) => x)
        } else {
          const ordering = moment.utc(r.resourcegroups[0].end)
          if (moment().utcOffset() > 0) {
            ordering.subtract(moment().utcOffset(), 'minute')
          } else {
            ordering.add(moment().utcOffset(), 'minute')
          }
          r.ordering = ordering.unix()
          r.occurency = { date: moment.utc(r.resourcegroups[0].start).format('YYYY-MM-DD') }
          return r
        }
      })
      .flat(3)
      .filter((r) => {
        if (appconfig.nextseasonhide) {
          if (moment(r.occurency.date).isAfter(appconfig.nextseasonhide)) {
            return false
          } else {
            return true
          }
        }
        return true
      })
      .map((r) => {
        r.vs = getStatus(r)
        return r
      })

    _m.sort(function (a, b) {
      if (a.ordering < b.ordering) {
        return -1
      } else if (a.ordering > b.ordering) {
        return 1
      } else {
        return 0
      }
    })


    let _onsale = _m.filter((x) => x.vs.isOnSale && !x.vs.isCancelled)
    let _upcoming = _m.filter((x) => x.vs.isUpcoming && !x.vs.isCancelled)
    let _past = _m.filter((x) => x.vs.isPast && !x.vs.isCancelled).reverse()
    let _cancelled = _m.filter((x) => x.vs.isCancelled).reverse()
    const _unpaid = _m.filter((x) => {
      if (x.vs.saleState && x.vs.saleState.p === 1) return false
      if (!x.vs.isPaid) {
        if (x.vs.isStored) {
          return true
        }
        if (x.vs.isCancelled) {
          return false
        }
        return true
      }
      return false
    }).reverse()

    return {
      reservations: _upcoming,
      pastreservations: _past,
      onsale: _onsale,
      cancelled: _cancelled,
      unpaid: _unpaid,
      // owns: firestore.data['ownReservations'],
      accesscode,
    }
  } else {
    return { reservations: undefined }
  }
}

const withReservations = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [{ collection: 'reservations' }],
      where: [['validtime', '>', moment.utc().unix()]],
      storeAs: 'ownReservations',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
  ]),
  connect(mapStateToProps)
)

const checkCourtAndTimeChange = (reservation, parent) => {
  const courtchanged = reservation.resourcegroups[0].resources[0].id !== parent.resourcegroups[0].resources[0].id
  // check time
  const _parentstart = moment.utc(parent.resourcegroups[0].start)
  const _parentend = moment.utc(parent.resourcegroups[0].end)
  const _start = moment.utc(reservation.resourcegroups[0].start)
  const _end = moment.utc(reservation.resourcegroups[0].end)
  const timechanged = !(
    _parentstart.hour() === _start.hour() &&
    _parentstart.minute() === _start.minute() &&
    _parentend.hour() === _end.hour() &&
    _parentend.minute() === _end.minute()
  )
  return { courtchanged, timechanged }
}

const ReservationsScreen = ({
  auth,
  dispatch,
  reservations,
  pastreservations,
  onsale,
  cancelled,
  unpaid,
  accesscode,
  appconfig,
}) => {
  const firebase = useFirebase()
  let location = useLocation()
  let defaultLanguage = 'FI'
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [servertime, setServertime] = useState(null)
  const [canceltime, setCanceltime] = useState(null)
  const [confirmatioOpen, setConfirmationOpen] = useState(false)
  const [storageConfirmatioOpen, setStorageConfirmationOpen] = useState(false)
  const [selectedkey, setSelectedKey] = useState(null)
  const [checkingSaleStatus, setCheckingSaleStatus] = useState(false)
  const [conflict, setConflict] = useState(null)
  const [cancelTitle, setCancelTitle] = useState('Haluatko varmasti perua vuoron?')
  const [showReservations, setShowReservations] = useState('upcoming')
  const [expanded, setExpanded] = useState({})
  const [selectedReservations, setSelectedReservations] = useState([])
  const [walletsDisabled, setWalletsDisabled] = useState(false)
  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }

  useEffect(() => {
    moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
  }, [defaultLanguage])

  const updateTimes = () => {
    const degugTime = new URLSearchParams(location.search).get('time')
    fetch('/index.html').then((response) => {
      const time = degugTime ? moment(degugTime) : moment(new Date(response.headers.get('Date')))
      const offset = time.utcOffset()
      time.add(offset, 'minutes')
      setServertime(time)
      let t2 = moment(time).add(appconfig.cancellimit || 24, 'hours')
      setCanceltime(t2)
    })
  }

  useEffect(() => {
    updateTimes()
    const timer = setInterval(() => updateTimes(), 120000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // console.log('update disable')
    const wallets = selectedReservations.map((x) => {
      return getWallet(appconfig, { category: x.resourcegroups[0].resources[0].category })
    })
    const isAllSameWallet = wallets.every((val, i, arr) => val === arr[0])
    // console.log('wallets', wallets)
    // console.log('isAllSameWallet', isAllSameWallet)
    // console.log('selectedReservations', selectedReservations)
    setWalletsDisabled(!isAllSameWallet)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReservations])

  // const goToPayReservation = (reservation, d) => {
  //   dispatch({
  //     type: 'UPDATE_PAYABLE_RESERVATION',
  //     payload: reservation,
  //   })
  //   history.push(appconfig.pathPrefix + '/payonly')
  // }

  const goToPayReservations = () => {
    dispatch({
      type: 'UPDATE_PAYABLE_RESERVATION',
      payload: selectedReservations,
    })
    history.push(appconfig.pathPrefix + '/payonly')
  }

  const cancelReservation = async () => {
    setConfirmationOpen(false)
    setLoading(true)
    let reservation = reservations.find((r) => r.selectkey === selectedkey)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testreservations-cancelv2' : 'reservations-cancelv2'
    const cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(reservation)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  const storageReservation = async () => {
    setStorageConfirmationOpen(false)
    setLoading(true)
    let reservation = reservations.find((r) => r.selectkey === selectedkey)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testreservations-store' : 'reservations-store'
    const store = firebase.functions().httpsCallable(fn)
    try {
      const response = await store(reservation)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  const cancelConfirmation = (key, toState) => {
    if (toState === 'onsale') {
      setCancelTitle('Haluatko varmasti laittaa vuoron myyntiin?')
    } else {
      setCancelTitle('Haluatko varmasti peruuttaa vuoron?')
    }
    setSelectedKey(key)
    setConfirmationOpen(true)
  }

  const storageConfirmation = (key) => {
    setSelectedKey(key)
    setStorageConfirmationOpen(true)
  }

  const splitPayment = (reservation) => {
    dispatch({
      type: 'UPDATE_SPLITTABLE_RESERVATION',
      payload: reservation,
    })
    history.push(appconfig.pathPrefix + '/splitpayment')
  }

  const selectRow = (reservation) => {
    reservation.collection = 'reservations'
    setSelectedReservations((x) => [...x, reservation])
  }

  const unselectRow = (id, occurencydate) => {
    // console.log('unselect', id, occurencydate)
    setSelectedReservations((x) =>
      x.filter((x) => {
        // console.log(x)
        return !(x.id === id && x.occurency.date === occurencydate)
      })
    )
  }

  const getOwnReservationRow = (r, ispast, isUnpaidList) => {
    const selected = selectedReservations.find((x) => x.id === r.id && x.occurency.date === r.occurency.date)
    let changes = { courtchanged: false, timechanged: false }
    if (r.parentid) {
      const parent = reservations.find((x) => x.id === r.parentid)
      if (parent) {
        changes = checkCourtAndTimeChange(r, parent)
      }
    }
    const venue = getWallet(appconfig, { category: r.resourcegroups[0].resources[0].category })

    const reservationAccesscode = r.accesscode ? r.accesscode : accesscode

    return (
      <ReservationRow
        key={r.selectkey}
        r={r}
        venue={venue}
        ispast={ispast}
        isUnpaidList={isUnpaidList}
        servertime={servertime}
        canceltime={canceltime}
        accesscode={reservationAccesscode}
        changes={changes}
        onCancel={cancelConfirmation}
        onStorage={storageConfirmation}
        onDownloadReceipt={downloadReceipt}
        selected={selected}
        onSelect={selectRow}
        onUnselect={unselectRow}
        onSplitpayment={splitPayment}
      />
    )
  }

  const checkSaleStatus = async (slots) => {
    try {
      firebase.functions().region = 'europe-west1'

      const saleStatus = firebase.functions().httpsCallable('redis-salestatus')

      const conflictData = await saleStatus({ slots, customerid: appconfig.accountkey })
      // console.log('got conflicts', conflictData)
      return conflictData.data
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  const downloadReceipt = (id, date) => {
    let downloadUrl =
      'https://europe-west1-falcon-328a1.cloudfunctions.net/receipt-download?cid=' + appconfig.accountkey + '&id=' + id
    if (date) {
      downloadUrl += '&date=' + date
    }
    window.open(downloadUrl, '_blank')
  }

  const handleExpandClick = (timeIndex) => {
    let v = true
    if (expanded[timeIndex]) v = false
    setExpanded({ ...expanded, [timeIndex]: v })
  }

  const getCurrentReservations = (agg, ai, i) => {
    if (i > 0) {
      return (
        <React.Fragment key={ai + '-' + i + '-u'}>
          <Row
            className='align-baseline'
            style={{ marginRight: 0, marginLeft: 0, marginTop: 5, paddingBottom: 5, alignItems: 'baseline' }}
            // key={ai}
          >
            <Col xs={2} md={1}>
              <IconButton onClick={() => handleExpandClick(ai)}>
                {expanded[ai] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Col>
            <Col>
              <h6 style={{ cursor: 'pointer', textTransform: 'lowercase' }} onClick={() => handleExpandClick(ai)}>
                {moment.utc(ai, 'YYYYMM').format('MMMM YYYY')}, {agg[ai].length}{' '}
                {agg[ai].length < 2 ? t('reservation') : t('reservations')}
              </h6>
            </Col>
          </Row>
          <Collapse in={expanded[ai]} timeout='auto' unmountOnExit>
            {agg[ai]}
          </Collapse>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment key={ai + '-' + i + '-ue'}>
          <Row
            className=''
            style={{ marginRight: 0, marginLeft: 0, marginTop: 5, paddingBottom: 5 }}
            // key={ai}
          >
            <Col xs={2} md={1}></Col>
            <Col>
              <h6>
                {moment.utc(ai, 'YYYYMM').format('MMMM YYYY')}, {agg[ai].length}{' '}
                {agg[ai].length < 2 ? t('reservation') : t('reservations')}
              </h6>
            </Col>
          </Row>
          {agg[ai]}
        </React.Fragment>
      )
    }
  }

  const getFilteredReservations = (agg, ai, i) => {
    return (
      <React.Fragment key={ai + '-' + i + '-p'}>
        <Row
          className='align-baseline'
          style={{ marginRight: 0, marginLeft: 0, marginTop: 5, paddingBottom: 5, alignItems: 'baseline' }}
          // key={ai}
        >
          <Col xs={2} md={1}>
            <IconButton onClick={() => handleExpandClick(ai)}>
              {expanded[ai] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </Col>
          <Col>
            <h6 style={{ cursor: 'pointer', textTransform: 'lowercase' }} onClick={() => handleExpandClick(ai)}>
              {moment.utc(ai, 'YYYYMM').format('MMMM YYYY')}, {agg[ai].length}{' '}
              {agg[ai].length < 2 ? t('reservation') : t('reservations')}
            </h6>
          </Col>
        </Row>
        <Collapse in={expanded[ai]} timeout='auto' unmountOnExit>
          {agg[ai]}
        </Collapse>
      </React.Fragment>
    )
  }

  const aggregateReservations = (reservations, current, isUnpaidView, reverse) => {
    const agg = reservations.reduce((a, r, i) => {
      // if (!r.occurency) console.log(r)
      let index = moment.utc(r.resourcegroups[0].start).format('YYYYMM')
      let isPast = false
      if (r.isrecurring) {
        const ocMoment = moment.utc(r.occurency.date)
        index = ocMoment.format('YYYYMM')
        isPast = moment
          .utc(r.resourcegroups[0].start)
          .year(ocMoment.year())
          .month(ocMoment.month())
          .date(ocMoment.date())
          .isBefore(moment.utc(), 'hour')
      } else {
        isPast = moment.utc(r.resourcegroups[0].start).isBefore(moment.utc(), 'hour')
      }
      if (!a[index]) a[index] = []
      a[index].push(getOwnReservationRow(r, isPast, isUnpaidView))
      return a
    }, {})
    const list = Object.keys(agg)
      .sort()
      .map((ai, i) => {
        if (current) {
          return getCurrentReservations(agg, ai, i)
        } else {
          return getFilteredReservations(agg, ai, i)
        }
      })
    if (reverse) list.reverse()
    return list
  }

  if (isLoaded(onsale) && !conflict && !checkingSaleStatus) {
    setCheckingSaleStatus(true)
    const slots = onsale
      .map((r) => {
        if (!r.vs.saleState.s) return null
        if (r.resourcegroups && r.resourcegroups[0].resources) {
          let start = r.resourcegroups[0].start
          let end = r.resourcegroups[0].end
          // if (!r.occurency) console.log(r)
          if (r.occurency) {
            let od = moment.utc(r.occurency.date)
            start = moment.utc(start).year(od.year()).month(od.month()).date(od.date()).format()
            end = moment.utc(end).year(od.year()).month(od.month()).date(od.date()).format()
          }
          const _r = {
            start,
            end,
            resourceid: r.resourcegroups[0].resources[0].id,
            id: r.id,
          }
          if (r.occurency) {
            _r.occurency = r.occurency
          }
          return _r
        } else {
          return null
        }
      })
      .filter((x) => x)

    async function updateSalesStatus(slots) {
      const saleStatukses = await checkSaleStatus(slots)
      setConflict(saleStatukses)
      setCheckingSaleStatus(false)
      // console.log(saleStatukses)
    }
    updateSalesStatus(slots)
  }

  let loaded = isLoaded(reservations) && isLoaded(pastreservations) && isLoaded(onsale)
  let emptyReservations = isEmpty(reservations)
  // let emptyPast = isEmpty(pastreservations)
  let ownReservations =
    loaded || emptyReservations ? (
      <Card>
        <CardBody>Ei varauksia</CardBody>
      </Card>
    ) : (
      <Row>
        <Col className='text-center'>
          <Spinner color='primary' />
        </Col>
      </Row>
    )
  let ownPastReservations =
    loaded || emptyReservations ? (
      <Card>
        <CardBody>Ei varauksia</CardBody>
      </Card>
    ) : (
      <Row>
        <Col className='text-center'>
          <Spinner color='primary' />
        </Col>
      </Row>
    )
  let ownCancelledReservations =
    loaded || emptyReservations ? (
      <Card>
        <CardBody>Ei varauksia</CardBody>
      </Card>
    ) : (
      <Row>
        <Col className='text-center'>
          <Spinner color='primary' />
        </Col>
      </Row>
    )
  let ownUnpaidReservations =
    loaded || emptyReservations ? (
      <Card>
        <CardBody>Ei varauksia</CardBody>
      </Card>
    ) : (
      <Row>
        <Col className='text-center'>
          <Spinner color='primary' />
        </Col>
      </Row>
    )
  if (loaded) {
    if (reservations.length > 0) {
      ownReservations = aggregateReservations(reservations, true)
    }
    if (pastreservations.length > 0) {
      ownPastReservations = aggregateReservations(pastreservations, false, false, true)
    }
    if (cancelled.length > 0) {
      ownCancelledReservations = aggregateReservations(cancelled, false, false, true)
    }
    if (unpaid.length > 0) {
      ownUnpaidReservations = aggregateReservations(unpaid, false, true, true)
    }
  }
  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front'>
        <Row>
          <Col className='text-center tesoma-page-title'>
            <Typography variant='h5'>{t('omat_varaukset')}</Typography>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='text-center'>
              <Typography variant='body2'>
                {t('cancellation_policy.part1').replaceAll('{CANCELLIMIT}', appconfig.cancellimit || '24')}
              </Typography>
              <Typography variant='body2' gutterBottom>
                {t('cancellation_policy.part2')}
                <br />
                {t('cancellation_policy.part3')}
                <br />
                {t('cancellation_policy.part4')}
              </Typography>
              {appconfig.ownreservationsinfo ? (
                <Typography variant='body2' style={{ marginTop: 10, fontWeight: 600 }} gutterBottom>
                  {appconfig.ownreservationsinfo.text[i18n.language] || appconfig.ownreservationsinfo.text['fi']}
                </Typography>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='text-center mb-3'>
            <ButtonGroup style={{ flexWrap: 'wrap' }} size='sm'>
              <Button
                color='primary'
                active={showReservations === 'upcoming'}
                outline
                onClick={() => setShowReservations('upcoming')}
              >
                {t('show_upcoming')}
              </Button>
              <Button
                color='primary'
                active={showReservations === 'past'}
                outline
                onClick={() => setShowReservations('past')}
              >
                {t('show_history')}
              </Button>
              <Button
                color='primary'
                active={showReservations === 'cancelled'}
                outline
                onClick={() => setShowReservations('cancelled')}
              >
                {t('show_cancelled')}
              </Button>
              <Button
                color='primary'
                active={showReservations === 'unpaid'}
                outline
                onClick={() => setShowReservations('unpaid')}
              >
                {t('show_unpaid')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col md='12' className='mx-auto'>
            {loaded || emptyReservations ? (
              //  <List component='nav' aria-labelledby='nested-list-subheader'>
              <>
                {showReservations === 'past'
                  ? ownPastReservations
                  : showReservations === 'cancelled'
                  ? ownCancelledReservations
                  : showReservations === 'unpaid'
                  ? ownUnpaidReservations
                  : ownReservations}
              </>
            ) : (
              // </List>
              <div>Loading ...</div>
            )}
          </Col>
        </Row>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={walletsDisabled}
          // onClose={handleClose}
          message='Saldo/Lompakko maksutapa on käytössä vain jos kaikki valitut varaukset kohdistuvat samaan saldoon.'
          key={'verticalhorizontal'}
          // style={{ bottom: 90 }}
        />
        {selectedReservations.length > 0 ? (
          <Fab
            variant='extended'
            size='large'
            // color="orange"
            aria-label='selected'
            style={{
              margin: 0,
              top: 'auto',
              right: 20,
              bottom: 20,
              left: 'auto',
              position: 'fixed',
              backgroundColor: '#f9a825',
            }}
            onClick={goToPayReservations}
          >
            <MuiBadge
              style={{ marginRight: 10 }}
              badgeContent={selectedReservations.length}
              color='error'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              overlap='rectangular'
            >
              <ShoppingCartIcon />
            </MuiBadge>
            {' kassalle '} {selectedReservations.reduce((a, c) => a + c.vs.missingPayment.amount, 0) + ' €'}
          </Fab>
        ) : null}
      </Container>
      <Modal
        size='sm'
        centered
        isOpen={loading}
        backdrop={true}
        wrapClassName={'al-wrap'}
        modalClassName={'al-modal'}
        contentClassName={'loading-spinner'}
      >
        <ModalBody className='text-center'>
          <Spinner color='light' /> <p style={{ color: 'white' }}>{t('cancelling')}</p>
        </ModalBody>
      </Modal>
      <Dialog
        open={confirmatioOpen}
        onClose={() => setConfirmationOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{cancelTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)} color='secondary'>
            {t('back')}
          </Button>
          <Button onClick={() => cancelReservation()} color='danger' autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={storageConfirmatioOpen}
        onClose={() => setStorageConfirmationOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{t('storage_confirmation_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStorageConfirmationOpen(false)} color='secondary'>
            {t('back')}
          </Button>
          <Button onClick={() => storageReservation()} color='danger' autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default withAppconfig(withReservations(ReservationsScreen))
